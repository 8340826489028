import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import OtherSection from "./components/OtherSection";
import WhoWeAre from "./components/WhoWeAre";
import Companies from "./components/Companies";

import URLs from "./utils/apis";
import axios from "axios";
import { Helmet } from "react-helmet";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],

      countdown_title: "",
      founded_count: 0,
      project_count: 0,
      employee_count: 0,
      clients_count: 0,
      story_link: "",
      our_story_title: "",

      who_list: [],

      client_list: [],
      client_setting: [],

      cta_setting: {},

      title: "",
      description: "",
      keywords: "",
    };
  }
  componentDidMount() {
    this.get_about_settings();
  }
  get_about_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_about_page_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let item = response.data.data;
          this.setState({
            records: item.mission_list,

            countdown_title: item.countdown_setting.heading,
            founded_count: item.countdown_setting.founded,
            project_count: item.countdown_setting.projects,
            employee_count: item.countdown_setting.employee,
            clients_count: item.countdown_setting.satisfied_clients,
            story_link: item.countdown_setting.story_link,
            our_story_title: item.countdown_setting.our_story_title,

            who_list: item.who_list,

            client_list: item.client_list,
            client_setting: item.client_setting,

            cta_setting: item.cta_setting,

            title: item.meta_setting[0].title ? item.meta_setting[0].title : "",
            description: item.meta_setting[0].description
              ? item.meta_setting[0].description
              : "",
            keywords: item.meta_setting[0].keywords
              ? item.meta_setting[0].keywords
              : "",
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>

        <section class="about-hero-section">
          <h1 class="text-center" data-aos="fade-up">
            About Us
          </h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <img src="./assets/img/about-us-image.jpg" data-aos="fade-up" />
              </div>
            </div>
          </div>
        </section>

        {this.state.who_list.length > 0 && (
          <WhoWeAre list={this.state.who_list} />
        )}

        <section class="our-mission-section">
          <div class="container">
            {this.state.records &&
              this.state.records.map((item, index) => {
                let url = URLs.image_url + item.image;
                let clas = "";
                if (index == "1") {
                  clas = "reverse";
                }
                return (
                  <div class={`row mb-5 ${clas}`} key={index}>
                    <div class="col-lg-6">
                      <div
                        class="img"
                        data-aos="fade-up"
                        style={{
                          background: `url('${url}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    </div>
                    <div class="col-lg-6">
                      <h2
                        class="text-center"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        {item.title}
                      </h2>
                      <p data-aos="fade-up" data-aos-delay="150">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>

        <section class="our-story-section">
          <h1 class="text-center" data-aos="fade-up">
            {this.state.our_story_title}
          </h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div class="video-sec" data-aos="fade-up">
                  <iframe
                    width="100%"
                    // height="500px"
                    src={this.state.story_link}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>

        <section class="counter-down-section">
          <h1 class="text-center" data-aos="fade-up">
            {this.state.countdown_title}
          </h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="count-box">
                      <h3 data-aos="fade-up">{this.state.founded_count}</h3>
                      <p data-aos="fade-up" data-aos-delay="20">
                        Founded
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="count-box">
                      <h3 data-aos="fade-up" data-aos-delay="20">
                        {this.state.project_count}+
                      </h3>
                      <p data-aos="fade-up" data-aos-delay="40">
                        Projects
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="count-box" data-aos-delay="100">
                      <h3 data-aos="fade-up">{this.state.employee_count}</h3>
                      <p data-aos="fade-up" data-aos-delay="100">
                        Employee
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="count-box">
                      <h3 data-aos="fade-up">{this.state.clients_count}+</h3>
                      <p data-aos="fade-up" data-aos-delay="100">
                        Satisfied Clients
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.client_list.length > 0 && (
          <Companies
            setting={this.state.client_setting}
            list={this.state.client_list}
          />
        )}

        {this.state.cta_setting && (
          <OtherSection setting={this.state.cta_setting} />
        )}
        <Footer />
      </div>
    );
  }
}
