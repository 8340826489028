import React, { Component } from "react";
import Slider from "react-slick";

import axios from "axios";
import URLs from "../utils/apis";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      testimonial_list: [],
    };
  }
  componentDidMount() {
    this.setState({
      testimonial_list: this.props.list,
      title: this.props.settings.title,
    });
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <section class="testimonials-section">
        <h1 data-aos="fade-up" class="text-center">
          {this.state.title}
        </h1>
        <div class="container">
          <div class="row">
            <div class="col-lg-9 m-auto" data-aos="fade-up">
              {this.state.testimonial_list.length == 1 &&
                this.state.testimonial_list.map((item, index) => {
                  let url = URLs.image_url + item.image;
                  return (
                    <div class="single" key={index}>
                      <div class="inner">
                        <img src={url} alt="" />
                        <div class="right-side-content">
                          <p class="client-name">{item.client_name}</p>
                          <p class="company-name">{item.company_name}</p>
                          <p class="desc">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <Slider {...settings}>
                {this.state.testimonial_list.length > 1 &&
                  this.state.testimonial_list.map((item, index) => {
                    let url = URLs.image_url + item.image;
                    return (
                      <div class="single" key={index}>
                        <div class="inner">
                          <img src={url} alt="" />
                          <div class="right-side-content">
                            <p class="client-name">{item.client_name}</p>
                            <p class="company-name">{item.company_name}</p>
                            <p class="desc">{item.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
