import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import OtherSection from "./components/OtherSectionNew";

import URLs from "./utils/apis";
import { Helmet } from "react-helmet";
import { Spin } from "antd";

import axios from "axios";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "",
      project_list: [],
      copy_project_list: [],
      project_title: "",
      project_description: "",
      show_button_status: false,
      title: "",
      description: "",
      keywords: "",
    };
  }
  componentDidMount() {
    this.get_project_list();
    this.get_meta_settings();
  }
  get_project_list = () => {
    this.setState({ loading: true });
    let params = {
      page: "projects",
    };
    axios
      .post(URLs.get_project_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let index = 0;
          let newArr = [];
          for (let item of response.data.data) {
            if (index < 4) {
              newArr.push(item);
            }
          }
          this.setState({
            project_list: newArr,
            copy_project_list: response.data.data,
            show_button_status: response.data.data.length > 4 ? true : false,
            project_title: response.data.settings.title,
            project_description: response.data.settings.description,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "projects") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            title: title,
            description: description,
            keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  show_all_list = () => {
    let all = this.state.copy_project_list;
    this.setState({ project_list: all });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>

        <Spin spinning={this.state.loading}>
          <section class="recent-project-section">
            <div class="heading-section">
              <span>Portfolio</span>
              <h1>{this.state.project_title}</h1>
              <p>{this.state.project_description}</p>
            </div>
            <div class="container project-list">
              <div class="row">
                {this.state.project_list &&
                  this.state.project_list.map((item, index) => {
                    let url = URLs.image_url + item.image;
                    return (
                      <div class="col-lg-6" key={index}>
                        <a
                          href={`/project-details?project=${item.p_id}`}
                          data-aos="fade-up"
                        >
                          <div
                            class="single-project"
                            style={{
                              background: `url('${url}')`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            <div class="content one">
                              <h2>{item.name}</h2>
                              <p>{item.client_name}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
            {this.state.show_button_status == true && (
              <div style={{ marginTop: 40, marginBottom: 16 }}>
                <center>
                  <a
                    href="#"
                    onClick={() => this.show_all_list()}
                    class="know-more-button ubutton"
                  >
                    Show More &nbsp;&nbsp;&nbsp;
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.9">
                        <path
                          d="M15.8332 10.8317H10.8332V15.8317H9.1665V10.8317H4.1665V9.16504H9.1665V4.16504H10.8332V9.16504H15.8332V10.8317Z"
                          fill="#F6F3FF"
                        />
                      </g>
                    </svg>
                  </a>
                </center>
              </div>
            )}
          </section>
        </Spin>

        <OtherSection />
        <Footer />
      </div>
    );
  }
}
