import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Testimonials from "./components/Testimonials";
import OtherSection from "./components/OtherSection";
import WhoWeAre from "./components/WhoWeAre";
import Companies from "./components/Companies";
import TopSec from "./components/TopSec";
import TeamList from "./components/TeamList";
import Blog from "./components/Blog";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import URLs from "./utils/apis";
import { message, Spin, Modal, Button } from "antd";
import HeroModal from "./components/HeroModal";

import axios from "axios";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hero_title: "",
      hero_description: "",
      input_placeholder: "",
      hero_img: "",
      search_text: "",
      hero_img_url: "",
      service_title: "",
      service_description: "",

      project_title: "",
      project_description: "",
      formLoader: false,
      email: "",
      project_list: [],
      title: "",
      description: "",
      keywords: "",

      testimonial_list: [],
      testimonial_settings: [],

      client_list: [],
      client_setting: [],

      who_list: [],

      team_list: [],
      team_setting: [],

      blog_list: [],
      blog_setting: [],
      ctc_setting: {},

      modal_status: false,
    };
  }
  componentDidMount() {
    this.get_home_page_settings();
  }
  get_home_page_settings = () => {
    axios
      .get(URLs.get_home_page_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          console.log("home page settings", currentObj);

          let url = URLs.image_url + currentObj.hero_setting.hero_image;

          this.setState({
            title: currentObj.meta_setting[0].title,
            description: currentObj.meta_setting[0].description,
            keywords: currentObj.meta_setting[0].keywords,

            hero_title: currentObj.hero_setting.title,
            hero_description: currentObj.hero_setting.description,
            input_placeholder: currentObj.hero_setting.input_placeholder,
            hero_img_url: url,
            hero_img: currentObj.hero_setting.hero_image,

            service_title: currentObj.service_setting.title,
            service_description: currentObj.service_setting.description,

            service_list: currentObj.service_list,
            project_list: currentObj.project_list,

            project_title: currentObj.project_settings.title,
            project_description: currentObj.project_settings.description,

            testimonial_list: currentObj.testimonial_list,
            testimonial_settings: currentObj.testimonial_settings,

            client_list: currentObj.client_list,
            client_setting: currentObj.client_setting,

            who_list: currentObj.who_list,

            team_list: currentObj.team_list,
            team_setting: currentObj.team_setting,

            blog_list: currentObj.blog_list,
            blog_setting: currentObj.blog_setting,

            ctc_setting: currentObj.ctc_setting,

            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  save_subscriber = () => {
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    const isValid = this.validateEmail(this.state.email);
    if (isValid == false) {
      message.warning("Invalid email address");
      return;
    }
    this.setState({ formLoader: true });
    let params = {
      email: this.state.email.toLowerCase(),
    };
    axios
      .post(URLs.save_subscriber, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ formLoader: false, email: "" });
        } else {
          message.warning(response.data.message);
          this.setState({ formLoader: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        {/* Start Hero section  */}
        <section class="hero-section" id="hero-section">
          <div class="overlap-bg"></div>
          <div class="content">
            <div class="container upper">
              <div class="row">
                <div class="col-lg-6">
                  <div class="left-section">
                    <h1 data-aos="fade-up" data-aos-delay="3000">
                      {this.state.hero_title}
                    </h1>
                    <p data-aos="fade-up" data-aos-delay="3000">
                      {this.state.hero_description}
                    </p>
                    <Spin spinning={this.state.formLoader}>
                      <>
                        <input
                          type="text"
                          class="search-text"
                          placeholder={this.state.input_placeholder}
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <button
                          class="subscribe-button ubutton"
                          onClick={() => this.save_subscriber()}
                        >
                          BOOK A CALL
                        </button>
                      </>
                    </Spin>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="right-section d-none d-sm-none d-xs-none d-md-none d-lg-block d-xl-block">
                    {this.state.hero_img != "" ? (
                      <div
                        class="white-block"
                        data-aos="fade-up"
                        data-aos-delay="3000"
                        style={{
                          color: "red",
                          background: `url('${this.state.hero_img_url}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    ) : (
                      <div
                        class="white-block"
                        data-aos="fade-up"
                        data-aos-delay="3000"
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero section  */}
        {/* Start who we are section  */}

        {this.state.who_list.length > 0 && (
          <WhoWeAre list={this.state.who_list} />
        )}
        {/* End who we are section  */}
        {/* Start service section  */}
        <section class="service-section pt-0">
          <div className="d-flex justify-content-center">
            <div class="heading-section pt-0">
              <div class="line" data-aos="fade-up"></div>
              <h1
                data-aos="fade-up"
                data-aos-delay="150"
                style={{ paddingBottom: 16 }}
              >
                {this.state.service_title}
              </h1>
              <p data-aos="fade-up" data-aos-delay="200">
                {this.state.service_description}
              </p>
            </div>
          </div>
          <div class="container service-list">
            <div class="row">
              {this.state.service_list &&
                this.state.service_list.map((item, index) => {
                  let url = URLs.image_url + item.image;
                  let type = "";
                  if (index % 2 === 0) {
                  } else {
                    type = "reverse";
                  }
                  return (
                    <div
                      class="col-lg-10 m-auto"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      key={index}
                    >
                      <a href={`/service-details?service=${item.service_id}`}>
                        <div class={`single ${type}`}>
                          <div
                            class="img"
                            // style={{
                            //   background: `url('${url}')`,
                            //   backgroundSize: "cover",
                            //   backgroundPosition: "center",
                            // }}
                          >
                            <img src={url} />
                          </div>
                          <div class="content">
                            <h2>{item.name}</h2>
                            <p>{item.short_description}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
          <center>
            <button
              class="know-more-button ubutton"
              onClick={() => window.location.assign("/services")}
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Know More &nbsp;&nbsp;&nbsp;
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: "relative", top: -1 }}
              >
                <path
                  d="M0.333496 9.5V4.5H7.00016V0.466667L13.5335 7L7.00016 13.5333V9.5H0.333496Z"
                  fill="#263F7B"
                />
              </svg>
            </button>
          </center>
        </section>
        {/* End service section  */}
        <section class="recent-project-section">
          <div className="d-flex justify-content-center">
            <div class="heading-section">
              <h1 style={{ paddingTop: "0 !Important" }} data-aos="fade-up">
                {this.state.project_title}
              </h1>
              <p data-aos="fade-up" data-aos-delay="150">
                {this.state.project_description}
              </p>
            </div>
          </div>
          <div class="container project-list">
            <div class="row">
              {this.state.project_list &&
                this.state.project_list.map((item, index) => {
                  let url = URLs.image_url + item.image;
                  let description = "";
                  if (item.description.length < 300) {
                    description = item.description;
                  } else {
                    description = item.description.slice(0, 300) + "...";
                  }
                  return (
                    <div
                      class="col-lg-11 m-auto"
                      key={index}
                      data-aos="fade-up"
                    >
                      <a href={`/project-details?project=${item.p_id}`}>
                        <div class="single">
                          <div
                            class="img"
                            style={{
                              background: `url('${url}')`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>
                          <div class="content">
                            <h2>{item.name}</h2>
                            <h6>{item.client_name}</h6>
                            <p>{parse(description)}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
          <div style={{ paddingTop: 40 }}>
            <center>
              <a
                href="/projects"
                class="know-more-button ubutton"
                style={{ top: -13 }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Know More &nbsp;&nbsp;&nbsp;
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "relative", top: -1 }}
                >
                  <path
                    d="M0.333496 9.5V4.5H7.00016V0.466667L13.5335 7L7.00016 13.5333V9.5H0.333496Z"
                    fill="#fff"
                  />
                </svg>
              </a>
            </center>
          </div>
        </section>

        {this.state.client_list.length > 0 && (
          <Companies
            setting={this.state.client_setting}
            list={this.state.client_list}
          />
        )}

        {this.state.team_list.length > 0 && (
          <TeamList
            list={this.state.team_list}
            setting={this.state.team_setting}
          />
        )}

        {this.state.testimonial_list.length > 0 && (
          <Testimonials
            list={this.state.testimonial_list}
            settings={this.state.testimonial_settings}
          />
        )}

        {this.state.blog_list.length > 0 && (
          <Blog list={this.state.blog_list} setting={this.state.blog_setting} />
        )}
        {this.state.cta_setting && (
          <OtherSection setting={this.state.ctc_setting} />
        )}

        <Footer />

        <Modal
          visible={this.state.modal_status}
          footer={[]}
          onCancel={() => this.setState({ modal_status: false })}
          width={800}
        >
          <HeroModal />
        </Modal>
      </div>
    );
  }
}
