import React, { Component } from "react";

export default class HeroModal extends Component {
  render() {
    return (
      <div className="row hero_modal">
        <div className="col-lg-6">
          <h1>Heading</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            mollitia, molestiae quas vel sint commodi repudiandae consequuntur
            voluptatum laborum numquam blanditiis harum quisquam eius sed odit
            fugiat iusto fuga
          </p>
          <a href="#" className="download-kit-buton ubutton">
            Download Your Kit
          </a>
        </div>
        <div className="col-lg-6">
          <img src="https://images.unsplash.com/photo-1715509790057-a44470a63cc2?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
        </div>
      </div>
    );
  }
}
