import React, { Component } from "react";
import $ from "jquery";
import Menu from "./Menu";
import ScreenLoader from "./ScreenLoader";
import URLs from "../utils/apis";
import axios from "axios";

export default class Header2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollOffset: 0,
      button_text: "",
      button_link: "",
      header_logo: "",
      loading: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    this.get_header_footer_settings();
    window.addEventListener("scroll", this.handleScroll);
  }
  get_header_footer_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_footer_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          // console.log("blog data", response);
          let header_logo = URLs.image_url + currentObj.header_logo;
          this.setState({
            button_text: currentObj.button_text,
            button_link: currentObj.button_link,
            header_logo: header_logo,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    const scrollOffset = window.pageYOffset;
    if (scrollOffset > 50) {
      $("header").addClass("sticky");
    } else {
      $("header").removeClass("sticky");
    }
    this.setState({ scrollOffset });
  }
  render() {
    return (
      <>
        <ScreenLoader />
        <header className="" id="header" style={{ background: "#263f7b" }}>
          <div class="container">
            <div className="row justify-content-between align-items-center">
              <div class="col-9 col-sm-5 col-lg-3">
                <a href="/" className="logo">
                  <img src="./assets/img/logo-transparent.webp" alt="logo" />
                </a>
              </div>
              <div class="col-3 col-sm-7 col-lg-8">
                <div class="d-sm-flex d-flex d-md-none d-lg-none d-xl-none justify-content-between">
                  <button
                    class="ubutton d-none d-sm-none d-xs-none d-md-none d-lg-block d-xl-block"
                    style={{ marginLeft: 12 }}
                  >
                    Book a call
                  </button>
                  <button class="toggle-button">
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.999937 12.0139C0.716637 12.0096 0.480804 11.91 0.292437 11.715C0.103394 11.5208 0.0110451 11.282 0.0153902 10.9987C0.0197353 10.7154 0.119364 10.4796 0.314275 10.2912C0.508531 10.1022 0.747308 10.0098 1.03061 10.0142L17.0287 10.2595C17.312 10.2639 17.5479 10.3635 17.7362 10.5584C17.9253 10.7527 18.0176 10.9914 18.0133 11.2747C18.0089 11.558 17.9093 11.7939 17.7144 11.9822C17.5201 12.1713 17.2814 12.2636 16.9981 12.2593L0.999937 12.0139ZM1.07662 7.01451C0.793315 7.01017 0.557482 6.91054 0.369116 6.71563C0.180072 6.52137 0.0877232 6.28259 0.0920683 5.99929C0.0964134 5.71599 0.196047 5.47983 0.390969 5.29079C0.585214 5.10242 0.823986 5.0104 1.10729 5.01475L17.1054 5.26012C17.3887 5.26446 17.6245 5.36376 17.8129 5.558C18.002 5.75292 18.0943 5.99204 18.09 6.27534C18.0856 6.55864 17.986 6.79447 17.7911 6.98284C17.5968 7.17188 17.358 7.26423 17.0747 7.25988L1.07662 7.01451ZM1.15329 2.0151C0.869993 2.01076 0.634155 1.91146 0.445778 1.71722C0.256745 1.52229 0.164401 1.28318 0.168746 0.999882C0.173092 0.716582 0.272725 0.480416 0.467647 0.291383C0.661892 0.103006 0.900664 0.0109905 1.18396 0.0153356L17.1821 0.260706C17.4654 0.265051 17.7012 0.364346 17.8896 0.558591C18.0786 0.753513 18.171 0.992624 18.1666 1.27592C18.1623 1.55922 18.0627 1.79539 17.8677 1.98442C17.6735 2.1728 17.4347 2.26482 17.1514 2.26047L1.15329 2.0151Z"
                        fill="#5928e5"
                      />
                    </svg>
                  </button>
                </div>
                <ul class="d-sm-none d-none d-lg-flex">
                  <li>
                    <a
                      href="/"
                      class={window.location.pathname == "/" ? "active" : ""}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about-us"
                      class={
                        window.location.pathname == "/about-us" ? "active" : ""
                      }
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/projects"
                      class={
                        window.location.pathname == "/projects" ? "active" : ""
                      }
                    >
                      Projects
                    </a>
                  </li>
                  <li>
                    <a
                      href="/services"
                      class={
                        window.location.pathname == "/services" ||
                        window.location.pathname == "/service-details"
                          ? "active"
                          : ""
                      }
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="/blog"
                      class={
                        window.location.pathname == "/blog" ||
                        window.location.pathname == "/blog-details"
                          ? "active"
                          : ""
                      }
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      class={
                        window.location.pathname == "/contact" ? "active" : ""
                      }
                    >
                      Contacts
                    </a>
                  </li>
                  <li>
                    <a href={this.state.button_link} target="_blank">
                      <button className="ubutton d-none d-sm-none d-xs-none d-md-none d-lg-block d-xl-block">
                        Book a call &nbsp;&nbsp;&nbsp;
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ position: "relative", top: -2 }}
                        >
                          <path
                            d="M14.625 15.5C12.8889 15.5 11.1736 15.1217 9.47917 14.365C7.78472 13.6083 6.24306 12.5353 4.85417 11.1458C3.46528 9.75694 2.3925 8.21528 1.63583 6.52083C0.879167 4.82639 0.500556 3.11111 0.5 1.375C0.5 1.125 0.583333 0.916667 0.75 0.75C0.916667 0.583333 1.125 0.5 1.375 0.5H4.75C4.94444 0.5 5.11806 0.566111 5.27083 0.698333C5.42361 0.830555 5.51389 0.986667 5.54167 1.16667L6.08333 4.08333C6.11111 4.30556 6.10417 4.49306 6.0625 4.64583C6.02083 4.79861 5.94444 4.93056 5.83333 5.04167L3.8125 7.08333C4.09028 7.59722 4.42 8.09361 4.80167 8.5725C5.18333 9.05139 5.60361 9.51333 6.0625 9.95833C6.49306 10.3889 6.94444 10.7883 7.41667 11.1567C7.88889 11.525 8.38889 11.8617 8.91667 12.1667L10.875 10.2083C11 10.0833 11.1633 9.98972 11.365 9.9275C11.5667 9.86528 11.7644 9.84778 11.9583 9.875L14.8333 10.4583C15.0278 10.5139 15.1875 10.6147 15.3125 10.7608C15.4375 10.9069 15.5 11.07 15.5 11.25V14.625C15.5 14.875 15.4167 15.0833 15.25 15.25C15.0833 15.4167 14.875 15.5 14.625 15.5Z"
                            fill="#263F7B"
                          />
                        </svg>
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            class="sidebar-menu d-sm-none d-none d-md-none d-lg-none d-xl-none"
            id="sidebar-popup"
          >
            <Menu />
          </div>
        </header>
      </>
    );
  }
}
