import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import { Spin } from "antd";
import parse from "html-react-parser";

import URLs from "./utils/apis";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

export default class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      author_name: "",
      date: "",
      description: "",

      client_name: "",
      project_name: "",
      image_url: "",

      info_list: [],
      solutions: [],
      statements: [],

      loading: false,
      project_id: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("project");
    if (id) {
      this.setState({ project_id: id }, () => this.get_service_list());
    } else {
      window.location.assign("/projects");
    }
    this.get_meta_settings();
  }
  get_service_list = () => {
    this.setState({ loading: true });
    let params = {
      page: "project",
    };
    axios
      .post(URLs.get_project_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          console.log("pro", response.data.data);
          for (let item of response.data.data) {
            if (item.p_id == this.state.project_id) {
              let url = URLs.image_url + item.image;
              this.setState({
                client_name: item.client_name,
                project_name: item.name,
                image_url: url,
                description: item.description,
                info_list: item.info_data ? JSON.parse(item.info_data) : [],
                statements: item.problem_statement
                  ? JSON.parse(item.problem_statement)
                  : [],
                solutions: item.solution_results
                  ? JSON.parse(item.solution_results)
                  : [],
                // author_name: item.author,
                loading: false,
              });
              return;
            }
          }
          this.setState({
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "projectdetails") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>
        {/* Start blog details section  */}
        <Spin spinning={this.state.loading}>
          <section class="post-details">
            <div class="heading-section" style={{ maxWidth: "85%" }}>
              <span>{this.state.client_name}</span>
              <h1>{this.state.project_name}</h1>
            </div>
            <div class="container">
              <div className="info_list row justify-content-center">
                {this.state.info_list &&
                  this.state.info_list.map((item, index) => {
                    // side-border 2px solid #263f7b
                    let className = "";
                    if (window.innerWidth < 600) {
                      if (index > 0 && index == "1") {
                        className = "blue-top-border";
                      }
                      if (index > 1 && index == "2") {
                        className = "blue-mobile-both-border";
                      }
                    } else {
                      if (index > 0 && index == "1") {
                        className = "blue-left-border";
                      }
                      if (index > 1 && index == "2") {
                        className = "blue-both-border";
                      }
                    }

                    return (
                      <div className="col-lg-4" key={index}>
                        <div
                          className={
                            index == "1"
                              ? "single " + className
                              : "single" + className
                          }
                        >
                          <h2>{item.title}</h2>
                          <p>{item.desc}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="single-post">
                    {this.state.image_url && (
                      <img data-aos="fade-up" src={this.state.image_url} />
                    )}
                    <div
                      class="description"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      {parse(this.state.description)}
                    </div>
                    <div
                      class="container"
                      data-aos="fade-up"
                      data-aos-delay="90"
                    >
                      <div className="p_information_list row pl-0">
                        <div className="col-lg-6 pl-0">
                          <div className="single">
                            <h2>Problem Statement</h2>
                            <ul>
                              {this.state.statements &&
                                this.state.statements.map((item, index) => {
                                  return <li>{item.title}</li>;
                                })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 pl-0">
                          <div className="single">
                            <h2>Solutions & Results</h2>
                            <ul>
                              {this.state.solutions &&
                                this.state.solutions.map((item, index) => {
                                  return <li>{item.title}</li>;
                                })}
                            </ul>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>

        {/* End blog details section  */}
        <Footer />
      </div>
    );
  }
}
