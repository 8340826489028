import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import URLs from "./utils/apis";
import axios from "axios";
import { Spin } from "antd";
import { Helmet } from "react-helmet";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      loading: false,
      service_list: [],
      copy_service_list: [],
      show_more_button: false,

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    this.get_service_list();
    this.get_meta_settings();
  }
  get_service_list = () => {
    this.setState({ loading: true });
    let params = {
      page: "services",
    };
    axios
      .post(URLs.get_service_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let list = response.data.data;
          let index = 0;
          let tempArr = [];
          for (let item of list) {
            if (index < 5) {
              tempArr.push(item);
            }
            index++;
          }
          this.setState({
            service_list: tempArr,
            copy_service_list: list,
            show_more_button: list.length > 5 ? true : false,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  show_all_list = () => {
    let list = this.state.copy_service_list;
    this.setState({ service_list: list, show_more_button: false });
  };
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "services") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>
        <section class="service-section">
          <h1>Services</h1>
          <Spin spinning={this.state.loading}>
            <div class="container">
              <div class="row">
                <div class="col-lg-4 px-1 py-1" data-aos="fade-up">
                  <div class="we-do-section">
                    <div className="content">
                      <span> What we do </span>
                      <h2>What we do</h2>
                    </div>
                  </div>
                </div>
                {this.state.service_list &&
                  this.state.service_list.map((item, index) => {
                    let url = URLs.image_url + item.icon_img;
                    let description = "";
                    if (item.short_description.length < 200) {
                      description = item.short_description;
                    } else {
                      description =
                        item.short_description.slice(0, 200) + "...";
                    }
                    return (
                      <div class="col-lg-4 px-1 py-1" key={index}>
                        <div
                          class="single-service"
                          data-aos="fade-up"
                          data-aos-delay="50"
                        >
                          {item.icon_img ? (
                            <div class="service-icon" style={{ height: 80 }}>
                              <img src={url} />
                            </div>
                          ) : (
                            <div class="service-icon">
                              <img src={"./assets/img/service-img.png"} />
                            </div>
                          )}
                          <h5>{item.name}</h5>
                          <p>{description}</p>
                          <a
                            href={`/service-details?service=${item.service_id}`}
                            class="know-more-button"
                          >
                            Know More &nbsp;&nbsp;&nbsp;
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ position: "relative", top: -1 }}
                            >
                              <path
                                d="M0.333496 9.5V4.5H7.00016V0.466667L13.5335 7L7.00016 13.5333V9.5H0.333496Z"
                                fill="#fff"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {this.state.show_more_button == true && (
                <div style={{ marginTop: 48, marginBottom: 16 }}>
                  <center>
                    <a
                      href="#"
                      onClick={() => this.show_all_list()}
                      class="know-more-button ubutton"
                    >
                      Show More &nbsp;&nbsp;&nbsp;
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.9">
                          <path
                            d="M15.8332 10.8317H10.8332V15.8317H9.1665V10.8317H4.1665V9.16504H9.1665V4.16504H10.8332V9.16504H15.8332V10.8317Z"
                            fill="#263f7b"
                          />
                        </g>
                      </svg>
                    </a>
                  </center>
                </div>
              )}
            </div>
          </Spin>
        </section>

        <Footer />
      </div>
    );
  }
}
