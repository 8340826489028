import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import { Spin } from "antd";
import parse from "html-react-parser";

import URLs from "./utils/apis";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

export default class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      author_name: "",
      date: "",
      description: "",
      image_url: "",

      loading: false,
      service_id: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("service");
    if (id) {
      this.setState({ service_id: id }, () => this.get_service_list());
    } else {
      window.location.assign("/services");
    }

    this.get_meta_settings();
  }
  get_service_list = () => {
    this.setState({ loading: true });
    let params = {
      page: "services",
    };
    axios
      .post(URLs.get_service_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          for (let item of response.data.data) {
            if (item.service_id == this.state.service_id) {
              let date_string = moment(item.date).format("MMMM DD, YYYY");
              let url = URLs.image_url + item.image;
              this.setState({
                name: item.name,
                date: date_string,
                image_url: url,
                description: item.description,
                author_name: item.author,
                loading: false,
              });
              return;
            }
          }
          this.setState({
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "servicedetails") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>
        {/* Start blog details section  */}
        <Spin spinning={this.state.loading}>
          <section class="post-details">
            <div class="heading-section" style={{ maxWidth: "85%" }}>
              <span>Service</span>
              <h1>{this.state.name}</h1>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="single-post">
                    {this.state.image_url && <img src={this.state.image_url} />}
                    <div class="description">
                      {parse(this.state.description)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>

        {/* End blog details section  */}
        <Footer />
      </div>
    );
  }
}
