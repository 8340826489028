// let BASE_URL = "http://127.0.0.1:8000/api/";
// let image_url = "http://127.0.0.1:8000/";
// // https: https:
// let BASE_URL = "https://urbanspell.in/web-api/index.php/api/";
// let image_url = "http://api.urbanspell.in/";

let BASE_URL = "https://api.urbanspell.in/api/";
let image_url = "https://api.urbanspell.in/";

let URLs = {
  image_url: image_url,
  // login: BASE_URL + "admin/login",
  get_hero_section_settings: BASE_URL + "web/get_hero_section_settings",
  get_service_section_settings: BASE_URL + "web/get_service_section_settings",
  get_service_list: BASE_URL + "web/get_service_list",
  get_who_we_are_list: BASE_URL + "web/get_who_we_are_list",
  get_project_setting_details: BASE_URL + "web/get_project_setting_details",

  save_subscriber: BASE_URL + "web/save_subscriber",
  get_project_list: BASE_URL + "web/project/get",
  get_client_list: BASE_URL + "web/client/get",
  get_blog_list: BASE_URL + "web/blog/get",
  get_cta_list: BASE_URL + "web/cta/get",
  get_testimonial_list: BASE_URL + "web/testimonial/get",
  get_our_team_list: BASE_URL + "web/our_team/get",
  get_post_settings: BASE_URL + "web/post/get_settings",
  get_mission_vision_list: BASE_URL + "web/mission/get",
  get_countdown_settings: BASE_URL + "web/countdown/get",
  get_header_footer_settings: BASE_URL + "web/header_footer/get",
  get_social_link: BASE_URL + "web/social/get",
  get_meta_settings: BASE_URL + "web/meta-setting/get",

  get_footer_settings: BASE_URL + "web/footer-settings/get",
  get_home_page_settings: BASE_URL + "web/home-page/get",

  get_about_page_settings: BASE_URL + "web/about-page/get",
};

export default URLs;
