import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ records: this.props.list });
  }
  render() {
    return (
      <section class="who-we-are-section __inner_padding">
        <div class="container">
          <div class="row">
            {this.state.records &&
              this.state.records.map((item, index) => {
                let url = URLs.image_url + item.icon_img;
                return (
                  <div class="col-lg-6" key={index} data-aos="fade-up">
                    <div class="single">
                      <div class="icon">
                        <img src={url} fill="red" />
                        {/* <svg
                          width="134"
                          height="134"
                          viewBox="0 0 134 134"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M67.2502 0.333252C104.07 0.333252 133.917 30.1799 133.917 66.9999C133.94 82.3859 128.619 97.3024 118.864 109.2L118.997 109.347L118.117 110.093C111.865 117.488 104.074 123.429 95.2883 127.5C86.5025 131.572 76.9337 133.677 67.2502 133.667C47.5836 133.667 29.9169 125.153 17.7169 111.62L16.3836 110.087L15.5036 109.353L15.6369 109.193C5.88195 97.2978 0.561047 82.3837 0.583567 66.9999C0.583567 30.1799 30.4302 0.333252 67.2502 0.333252ZM67.2502 100.333C54.8502 100.333 43.6436 104.28 35.2969 109.707C44.513 116.622 55.7283 120.352 67.2502 120.333C78.7722 120.352 89.9875 116.622 99.2036 109.707C89.666 103.595 78.5779 100.342 67.2502 100.333ZM67.2502 13.6666C57.2137 13.6663 47.3808 16.498 38.8817 21.8363C30.3826 27.1745 23.5623 34.8026 19.2046 43.8437C14.847 52.8849 13.1289 62.9722 14.2479 72.9461C15.3669 82.92 19.2775 92.3758 25.5302 100.227C36.3369 92.4733 51.0836 86.9999 67.2502 86.9999C83.4169 86.9999 98.1636 92.4733 108.97 100.227C115.223 92.3758 119.134 82.92 120.253 72.9461C121.372 62.9722 119.653 52.8849 115.296 43.8437C110.938 34.8026 104.118 27.1745 95.6188 21.8363C87.1196 16.498 77.2867 13.6663 67.2502 13.6666ZM67.2502 26.9999C74.3227 26.9999 81.1054 29.8094 86.1064 34.8104C91.1074 39.8114 93.9169 46.5941 93.9169 53.6666C93.9169 60.739 91.1074 67.5218 86.1064 72.5228C81.1054 77.5237 74.3227 80.3333 67.2502 80.3333C60.1778 80.3333 53.395 77.5237 48.3941 72.5228C43.3931 67.5218 40.5836 60.739 40.5836 53.6666C40.5836 46.5941 43.3931 39.8114 48.3941 34.8104C53.395 29.8094 60.1778 26.9999 67.2502 26.9999ZM67.2502 40.3333C63.714 40.3333 60.3226 41.738 57.8221 44.2385C55.3217 46.739 53.9169 50.1304 53.9169 53.6666C53.9169 57.2028 55.3217 60.5942 57.8221 63.0947C60.3226 65.5952 63.714 66.9999 67.2502 66.9999C70.7864 66.9999 74.1778 65.5952 76.6783 63.0947C79.1788 60.5942 80.5836 57.2028 80.5836 53.6666C80.5836 50.1304 79.1788 46.739 76.6783 44.2385C74.1778 41.738 70.7864 40.3333 67.2502 40.3333Z"
                            fill="#5928E5"
                          />
                        </svg> */}
                      </div>
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }
}
