import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class OtherSectionNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      records: [],
      title: "",
      sub_title: "",
      description: "",
      button_text: "",
      button_link: "",
      is_section_visible: "",
    };
  }
  componentDidMount() {
    this.get_client_list();
  }
  get_client_list = () => {
    axios
      .get(URLs.get_cta_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            sub_title: currentObj.sub_title,
            description: currentObj.description,
            button_text: currentObj.button_text,
            button_link: currentObj.button_link,
            is_section_visible: currentObj.is_section_visible,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    if (this.state.is_section_visible == "1") {
      return (
        <div class="other-section">
          <div class="inner">
            <span class="sub-heading">{this.state.sub_title}</span>
            <h1>{this.state.title}</h1>
            <p>{this.state.description}</p>
            <a href={this.state.button_link} target="_blank">
              {this.state.button_text}
            </a>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
