import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      email: "",
      records: [],
      heading: "",
    };
  }
  componentDidMount() {
    this.setState({
      records: this.props.list,
      heading: this.props.setting.title,
    });
  }
  render() {
    return (
      <section class="client-list-section">
        <h6 data-aos="fade-up">{this.state.heading}</h6>
        <div class="client-list">
          {this.state.records &&
            this.state.records.map((item, index) => {
              let url = URLs.image_url + item.image;
              return (
                <div class="single" key={index} data-aos="fade-up">
                  <img src={url} alt="" />
                </div>
              );
            })}
        </div>
      </section>
    );
  }
}
