import React, { useEffect } from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import "animate.css";
import Home from "./app/Home";
import Contact from "./app/Contact";
import CommingSoon from "./app/CommingSoon";
import About from "./app/About";
import Blog from "./app/Blog";
import PostDetails from "./app/PostDetails";
import Projects from "./app/Projects";
import ProjectDetails from "./app/ProjectDetails";
import Services from "./app/Services";
import ServiceDetails from "./app/ServiceDetails";
import Test from "./app/Test";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      // duration: 1000, // Animation duration
      // once: true, // Whether animation should happen only once - while scrolling down
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<CommingSoon />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<PostDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project-details" element={<ProjectDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
