import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class TeamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      records: [],
      title: "",
      description: "",
      button_text: "",
      button_link: "",

      teal_list: [],
    };
  }
  componentDidMount() {
    this.setState({
      teal_list: this.props.list,
      title: this.props.setting.title,
      description: this.props.setting.description,
      button_text: this.props.setting.button_text,
      button_link: this.props.setting.button_link,
    });
  }
  render() {
    return (
      <section class="meet-our-team-section">
        <div class="container">
          <div class="row list">
            <div class="col-lg-6">
              <div class="row team-image-list">
                <h2
                  data-aos="fade-up"
                  class="d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none"
                >
                  Meet Our Team
                </h2>
                <p
                  data-aos="fade-up"
                  class="d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none"
                >
                  Discover the brilliance behind embrance. Our team blends
                  innovation and artistry to craft digital wonders tht
                  captivate.
                </p>
                {this.state.teal_list &&
                  this.state.teal_list.map((item, index) => {
                    let url = URLs.image_url + item.image;
                    return (
                      <div class="col-lg-4 col-sm-6 col-6" data-aos="fade-up">
                        <img src={url} alt="" />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="right-section">
                <h2
                  data-aos="fade-up"
                  class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block"
                >
                  {this.state.title}
                </h2>
                <p
                  data-aos="fade-up"
                  class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block"
                >
                  {this.state.description}
                </p>
                <a
                  href={this.state.button_link}
                  class="ubutton"
                  target="_blank"
                  data-aos="fade-up"
                >
                  <span>{this.state.button_text}</span> &nbsp;&nbsp;
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "relative", top: 1 }}
                  >
                    <path
                      d="M0.333496 9.5V4.5H7.00016V0.466667L13.5335 7L7.00016 13.5333V9.5H0.333496Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
