import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import URLs from "../utils/apis";
import { Spin } from "antd";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollOffset: 0,
      service_list: [],
      records: [],
      footer_logo_link: "",
      social_links: [],
      address: "",
      loading: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    const scrollOffset = window.pageYOffset;
    if (scrollOffset > 1500) {
      $("#back-to-top-button").removeClass("d-none");
    } else {
      $("#back-to-top-button").addClass("d-none");
    }
  }
  componentDidMount() {
    this.get_footer_settings();
  }
  get_footer_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_footer_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let footer_logo_link =
            URLs.image_url + currentObj.header_footer_settings.footer_logo;
          this.setState({
            social_links: currentObj.social_list,
            footer_logo_link: footer_logo_link,
            address: currentObj.header_footer_settings.footer_address,
            service_list: currentObj.service_list,
            records: currentObj.blog_posts,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <footer>
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-sm-12 col-12">
                {this.state.footer_logo_link && this.state.footer_logo_link && (
                  <img
                    class="logo"
                    src={this.state.footer_logo_link}
                    class="logo"
                    alt=""
                  />
                )}
                <h4 class="text-center">Registered Address</h4>
                <p class="text-center">{this.state.address}</p>
                <div class="social-icons">
                  <ul>
                    {this.state.social_links &&
                      this.state.social_links.map((item, index) => {
                        let url = URLs.image_url + item.icon_image;
                        return (
                          <li key={index}>
                            <a href={item.social_link} target="_blank">
                              <img src={url} />
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="link-list">
                  <ul>
                    <li>
                      <a href="/" class="bold">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/about-us" class="bold">
                        About us
                      </a>
                    </li>
                    <li>
                      <a href="/projects" class="bold">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="/services" class="bold">
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="/blog" class="bold">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="/contact" class="bold">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="link-list">
                  <h5>Services</h5>
                  <ul>
                    {this.state.service_list.map((item, index) => {
                      if (index < 5) {
                        return (
                          <li key={index}>
                            <a
                              href={`/service-details?service=${item.service_id}`}
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="link-list">
                  <h5>Blog</h5>
                  <ul>
                    {this.state.records &&
                      this.state.records.map((item, index) => {
                        return (
                          <li key={index}>
                            <a href={`/blog-details?post=${item.post_id}`}>
                              {item.title}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <a
          href="#top-div"
          class="back-to-top-button d-none"
          id="back-to-top-button"
        >
          <box-icon
            name="chevron-up"
            color="#fff"
            size={"40px"}
            style={{ fontWeight: "800" }}
          ></box-icon>
        </a>
      </Spin>
    );
  }
}
