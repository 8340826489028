import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class OtherSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      records: [],
      title: "",
      sub_title: "",
      description: "",
      button_text: "",
      button_link: "",
      is_section_visible: "",
    };
  }
  componentDidMount() {
    this.setState({
      title: this.props.setting.title,
      sub_title: this.props.setting.sub_title,
      description: this.props.setting.description,
      button_text: this.props.setting.button_text,
      button_link: this.props.setting.button_link,
      is_section_visible: this.props.setting.is_section_visible,
    });
  }
  render() {
    if (this.state.is_section_visible == "1") {
      return (
        <div class="other-section">
          <div class="inner">
            <span class="sub-heading">{this.state.sub_title}</span>
            <h1 data-aos="fade-up">{this.state.title}</h1>
            <p data-aos="fade-up" data-aos-delay="100">
              {this.state.description}
            </p>
            <a
              data-aos="fade-up"
              data-aos-delay="120"
              href={this.state.button_link}
              target="_blank"
            >
              {this.state.button_text}
            </a>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}
