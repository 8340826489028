import React, { Component } from "react";
import Header2 from "./layout/Header2";
import Footer from "./layout/Footer";
import OtherSection from "./components/OtherSectionNew";
import TopSec from "./components/TopSec";
import URLs from "./utils/apis";
import axios from "axios";
import { Helmet } from "react-helmet";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_map_link: "",
      social_links: [],
      contact1: "",
      contact2: "",
      email1: "",
      email2: "",
      contact_address: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    this.get_header_footer_settings();
    this.get_social_link();
    this.get_meta_settings();
  }
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "contacts") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_social_link = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_social_link)
      .then((response) => {
        console.log("social", response);
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            social_links: currentObj,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_header_footer_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_footer_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            contact_map_link: currentObj.contact_map_link,
            contact1: currentObj.contact1,
            contact2: currentObj.contact2,
            email1: currentObj.email1,
            email2: currentObj.email2,
            contact_address: currentObj.footer_address,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header2 />
        <TopSec />
        <div class="top-space"></div>
        {/* Start Contact section  */}
        <section class="contact-section">
          <h1 class="text-center">Contact Us</h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="contact-details">
                  <div class="icon">
                    <box-icon type="solid" name="phone" color="#fff"></box-icon>
                  </div>
                  {this.state.contact1 && (
                    <p>
                      <a
                        style={{ textDecoration: "none", color: "#3d3d3d" }}
                        href={`tel:${this.state.contact1}`}
                      >
                        {this.state.contact1}
                      </a>
                    </p>
                  )}
                  {this.state.contact2 && (
                    <p>
                      <a
                        style={{ textDecoration: "none", color: "#3d3d3d" }}
                        href={`tel:${this.state.contact2}`}
                      >
                        {this.state.contact2}
                      </a>
                    </p>
                  )}
                </div>
                <div class="contact-details">
                  <div class="icon">
                    <box-icon
                      type="solid"
                      name="envelope"
                      color="#fff"
                    ></box-icon>
                  </div>
                  {this.state.email1 && (
                    <p>
                      <a
                        style={{ textDecoration: "none", color: "#3d3d3d" }}
                        href={`mailto:${this.state.email1}`}
                      >
                        {this.state.email1}
                      </a>
                    </p>
                  )}
                  {this.state.email2 && (
                    <p>
                      <a
                        style={{ textDecoration: "none", color: "#3d3d3d" }}
                        href={`mailto:${this.state.email2}`}
                      >
                        {this.state.email2}
                      </a>
                    </p>
                  )}
                </div>
                <div class="contact-details">
                  <div class="icon">
                    <box-icon type="solid" name="map" color="#fff"></box-icon>
                  </div>
                  {this.state.contact_address && (
                    <a href={this.state.contact_map_link} target="_blank">
                      <p>{this.state.contact_address}</p>
                    </a>
                  )}
                </div>
                <div class="social">
                  <span>Follow us on</span>
                  <div class="list">
                    <ul>
                      {this.state.social_links &&
                        this.state.social_links.map((item, index) => {
                          let url = URLs.image_url + item.icon_image;
                          return (
                            <li key={index}>
                              <a href={item.social_link} target="_blank">
                                <img src={url} />
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 map-column">
                {this.state.contact_map_link && (
                  <iframe
                    src={this.state.contact_map_link}
                    width="100%"
                    height="700"
                    style={{ border: 0, borderRadius: 10 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
                {/* <img
                  src="./assets/img/contact.webp"
                  class="map-img w-100"
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </section>

        {/* End Contact section  */}
        <OtherSection />
        <Footer />
      </div>
    );
  }
}
